<template>
  <div>
    <div v-if="!visualizacao" class="d-flex align-end justify-space-between">
      <div class="titulo-pagina">
        <div>
          {{ $t('modulos.certificados.definir_derivas.titulo') }}
        </div>
        <div>
          <v-chip class="mr-1" small>
            Instrumento: {{ instrumento?.codigoTag }}
          </v-chip>
          <v-chip v-if="certificadoAtual" class="mr-1" small>
            Certificado: {{ certificadoAtual.certificado.nome }}
          </v-chip>
        </div>
      </div>
    </div>
    <v-stepper v-if="instrumento" v-model="e1" flat :elevation="0">
      <v-stepper-header>
        <v-stepper-step
          step="1"
          editable
          :complete="liberarConformidade"
          edit-icon="$complete"
        >
          {{ $t('modulos.certificados.definir_derivas.curva') }}
        </v-stepper-step>
        <v-divider />
        <v-stepper-step
          step="2"
          :editable="liberarConformidade"
          :complete="liberarConformidade"
          edit-icon="$complete"
        >
          {{ $t('modulos.certificados.definir_derivas.conformidade') }}
        </v-stepper-step>
        <v-divider />
        <v-stepper-step step="3" :editable="liberarIndicadores">
          {{ $t('modulos.certificados.definir_derivas.indicador') }}
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <dados-deriva
            ref="dados"
            :calibracao-id="calibracaoId"
            :instrumento="instrumento"
            :calibracao="calibracao"
            :visualizacao="visualizacao"
            :faixas-preenchidas="liberarConformidade"
            @cancelar="cancelar"
            @validar-faixas="validarFaixas"
            @mudar-certificado-atual="mudarCertificadoAtual"
          />
        </v-stepper-content>
        <v-stepper-content step="2">
          <conformidades
            ref="conformidades"
            :calibracao-id="calibracaoId"
            :form="conformidades"
            :visualizacao="visualizacao"
            @cancelar="cancelar"
            @buscar-conformidade="() => buscarConformidades(true)"
          />
        </v-stepper-content>
        <v-stepper-content step="3">
          <indicadores
            ref="indicadores"
            :calibracao-id="calibracaoId"
            :instrumento="instrumento"
            :visualizacao="visualizacao"
            @cancelar="cancelar"
            @voltar="voltar"
          />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
import DadosDeriva from './DadosDeriva';
import Conformidades from './Conformidades';
import Indicadores from './Indicadores';
import { InstrumentoService, CertificadoService, CalibracaoService } from '@common/services';
import { CalibracaoModel } from '@common/models/cadastros/CalibracaoModel';

export default {
  components: {
    DadosDeriva,
    Conformidades,
    Indicadores,
  },
  props: [
    'calibracaoId',
    'instrumentoId',
    'visualizacao',
    'alterarTituloPaginaVisualizarCertificado',
  ],
  data: function () {
    return {
      e1: 1,
      liberarConformidade: false,
      liberarIndicadores: false,
      instrumento: null,
      conformidades: [],
      certificadoAtual: null,
      calibracao: null,
    };
  },
  watch: {
    e1: function () {
      switch (this.e1) {
        case '1':
          this.$refs.dados.remontarGrafico();
          break;
        case '3':
          this.$refs.indicadores.inicializar();
          break;
      }
    },
  },
  mounted() {
    if (this.alterarTituloPaginaVisualizarCertificado) {
      this.$store.dispatch(
        'Layout/alterarTituloPagina',
        this.$t('modulos.certificados.titulos.visualizar_certificado')
      );
    } else {
      this.$store.dispatch(
        'Layout/alterarTituloPagina',
        this.$t('modulos.certificados.definir_derivas.titulo')
      );
    }

    this.buscarCalibracao();
  },
  methods: {
    buscarCalibracao: async function () {
      this.$store.dispatch('Layout/iniciarCarregamento', 'Carregando Dados...');
      await CalibracaoService.buscarPorId(this.calibracaoId)
        .then((res) => {
          this.calibracao = new CalibracaoModel(res.data);
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
          this.buscarInstrumento();
        });
    },
    mudarCertificadoAtual: function (certificado) {
      this.certificadoAtual = certificado;
    },
    buscarInstrumento: function () {
      this.$store.dispatch('Layout/iniciarCarregamento');

      InstrumentoService.buscar(this.instrumentoId)
        .then(async (res) => {
          this.instrumento = res.data;
          await this.validarFaixas(true);
          let ctx = this;
          setTimeout(function () {
            ctx.$refs.dados.inicializar();
          }, 2000);
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    validarFaixas: async function (alertarUsuario = false) {
      const promises = [];
      this.calibracao.faixas.forEach(faixa => {
        let promise = CertificadoService.buscarConformidade(
          this.calibracaoId,
          faixa.id
        );
        promises.push(promise);
      });

      await Promise.all(promises)
        .then(() => {
          this.liberarConformidade = true;
          if(alertarUsuario) this.e1 = '2';
        })
        .catch(() => {
          this.liberarConformidade = false;
        });

      await this.buscarConformidades(alertarUsuario);
    },
    buscarConformidades: async function (alertarUsuario = false) {
      this.$store.dispatch('Layout/iniciarCarregamento');
      this.liberarIndicadores = false;
      await CertificadoService.buscarConformidades(this.calibracaoId)
        .then((res) => {
          if (res.data.itens.length == 0) return;
          this.conformidades = res.data.itens.map((el) => {
            return {
              conformidadeItemId: el.id,
              conforme: el.conforme,
            };
          });

          this.liberarIndicadores = true;
          if(alertarUsuario) this.e1 = '3';
        })
        .catch(() => {})
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },

    definirRoteamento: function () {
      switch (this.$route.name) {
        case 'certificado-terceiro-definir-derivas':
          return 'certificado-terceiro';
        default:
          return 'certificado';
      }
    },
    cancelar: function () {
      this.confirmarCancelar().then(() => {
        this.$router.push({ name: this.definirRoteamento() });
      });
    },
    voltar: function () {
      this.$router.push({ name: this.definirRoteamento() });
    },
  },
};
</script>

<style lang="scss">
.modal-conformidades-titulo-dados {
  background-color: $color-primary-grey-100;

  span {
    font-weight: 500;
  }
}

.v-stepper__header {
  box-shadow: none !important;
}

.v-stepper__content {
  padding-top: 0;
}
</style>
