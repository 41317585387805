<template>
  <div>
    <div class="row">
      <input-text
        v-model="instrumento.codigoTag"
        :label="
          $t('modulos.certificados.definir_derivas.indicadores.instrumento')
        "
        class="col-12 col-md-4"
        disabled
      />
      <input-certificado-avaliacao
        v-model="form.certificadosIds"
        class="col-12 col-md-8"
        :label="
          $t('modulos.certificados.definir_derivas.indicadores.certificados')
        "
        :instrumento-id="instrumento.id"
        :disabled="visualizacao"
        @input="colocarCertificadoAtualDeVolta"
      />
    </div>
    <v-form
      ref="form"
      :key="keyForm"
      :disabled="form.certificadosIds.length == 0 || visualizacao"
    >
      <v-simple-table class="mt-4">
        <thead>
          <tr>
            <th scope="rol">
              {{
                this.$t(
                  'modulos.certificados.definir_derivas.indicadores.faixa'
                )
              }}
            </th>
            <th scope="rol">
              {{
                this.$t(
                  'modulos.certificados.definir_derivas.indicadores.indicadorDeriva'
                )
              }}
            </th>
            <th scope="rol">
              {{
                this.$t(
                  'modulos.certificados.definir_derivas.indicadores.valor'
                )
              }}
            </th>
            <th scope="rol">
              {{
                this.$t(
                  'modulos.certificados.definir_derivas.indicadores.desvioPadrao'
                )
              }}
            </th>
            <th scope="rol">
              {{
                this.$t(
                  'modulos.certificados.definir_derivas.indicadores.mediaResiduo'
                )
              }}
            </th>
            <th scope="rol">
              {{
                this.$t(
                  'modulos.certificados.definir_derivas.indicadores.maxResiduo'
                )
              }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="faixa in form.indicadores"
            :key="faixa.id"
          >
            <td>
              {{ faixa.descricao }}
            </td>
            <td>
              <input-select
                v-model="faixa.indicador"
                class="input-tabela"
                :options="opcoes"
                sem-label
                @input="buscarValorIndicador(faixa)"
              />
            </td>
            <td>
              <span v-if="!podeEditarFaixa(faixa)">{{
                faixa.valorDeriva | formatarNumeroComPrecisao
              }}</span>
              <input-text
                v-else
                v-model="faixa.valorDeriva"
                type="number"
                :numero-minimo="0"
                class="input-tabela"
                sem-label
              />
            </td>
            <td>
              {{ faixa.desvioPadraoResiduoCurva | formatarNumeroComPrecisao }}
            </td>

            <td>
              {{ faixa.mediaResiduoCurva | formatarNumeroComPrecisao }}
            </td>

            <td>
              {{ faixa.maximoResiduoCurva | formatarNumeroComPrecisao }}
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-form>
    <div
      v-if="!visualizacao"
      class="ma-0 mt-2 d-flex justify-end"
    >
      <botao-padrao
        class="mr-2"
        outlined
        color="secondary"
        @click="cancelar"
      >
        <v-icon>$mdiCancel</v-icon>
        {{ $t('geral.botoes.cancelar') }}
      </botao-padrao>

      <botao-padrao
        class="mr-2"
        :disabled="!podeSalvar()"
        @click="salvar"
      >
        {{ $t('geral.botoes.salvar') }}
      </botao-padrao>

      <botao-padrao
        :disabled="!podeSalvar()"
        @click="finalizar"
      >
        {{ $t('geral.botoes.finalizar') }}
      </botao-padrao>
    </div>
  </div>
</template>
<script>
import { InputCertificadoAvaliacao } from '@/components/inputs';
import { CertificadoService, EnumeradorService } from '@common/services';
import _ from 'lodash';
import { DropdownModel } from '@common/models/geral/DropdownModel';
export default {
  components: {
    InputCertificadoAvaliacao,
  },
  props: {
    calibracaoId: {
      type: String,
      required: true,
    },
    instrumento: {
      type: Object,
      required: true,
    },
    visualizacao: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      form: {
        certificadosIds: [],
        indicadores: [],
      },
      keyForm: 1,
      opcoes: [],
      watch: null,
    };
  },
  computed: {},
  mounted() {
    this.buscarEnumIndicadores();
    this.buscarIndicadores();
  },
  methods: {
    inicializar: async function () {
      this.buscarIndicadores();
    },
    podeSalvar: function () {
      return (
        this.form.certificadosIds.length > 0 &&
        this.form.indicadores.every((el) => el.valorDeriva != null)
      );
    },
    salvar: function () {
      if (!this.$refs.form.validate()) return;
      this.$store.dispatch('Layout/iniciarCarregamento');

      CertificadoService.salvarIndicadores(this.calibracaoId, this.form)
        .then(() => {
          this.toastSucesso(
            this.$t('modulos.certificados.definir_derivas.indicadores_sucesso')
          );
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    finalizar: function () {
      if (!this.$refs.form.validate()) return;
      this.confirmar(
        this.$t('geral.titulos.atencao'),
        this.$t('modulos.certificados.definir_derivas.confirmar_finalizar')
      ).then(() => {
        this.$store.dispatch('Layout/iniciarCarregamento');
        CertificadoService.salvarIndicadores(this.calibracaoId, this.form)
          .then(() => {
            CertificadoService.finalizarDeriva(this.calibracaoId)
              .then(() => {
                this.toastSucesso(
                  this.$t(
                    'modulos.certificados.definir_derivas.finalizar_sucesso'
                  )
                );
                this.$emit('voltar');
              })
              .finally(() => {
                this.$store.dispatch('Layout/terminarCarregamento');
              });
          })
          .catch(() => {
            this.$store.dispatch('Layout/terminarCarregamento');
          });
      });
    },
    cancelar: function () {
      this.$emit('cancelar');
    },
    buscarValorIndicador: function (faixa) {
      faixa.valorDeriva = '';
      if (!faixa.indicadores) return faixa;
      let indicador = faixa.indicadores.find(
        (el) => el.indicadorDeriva == faixa.indicador
      );
      if (indicador) faixa.valorDeriva = _.cloneDeep(indicador?.valorDeriva);
      return faixa;
    },
    podeEditarFaixa: function (faixa) {
      return (
        faixa.indicador == 'ValorFixo' ||
        faixa.indicador == 'PorcentagemDoPonto'
      );
    },
    buscarEnumIndicadores: function () {
      EnumeradorService.buscar('EnumIndicadorDeriva').then((res) => {
        this.opcoes = res;
      });
    },
    buscarIndicadores: function () {
      if (this.watch) this.watch();
      this.$store.dispatch('Layout/iniciarCarregamento');
      CertificadoService.buscarIndicadores(this.calibracaoId)
        .then((res) => {
          this.form.calibracao = res.data.calibracao;
          this.form.certificadosIds = new DropdownModel(res.data.certificados);
          this.form.indicadores = res.data.faixas;
        })
        .catch(() => {})
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
          this.watch = this.$watch('form.certificadosIds', function () {
            this.calcularIndicadores();
          });
          if (this.form.certificadosIds.length == 0)
            this.form.certificadosIds = [
              {
                text: this.form.calibracao.nome,
                value: this.form.calibracao.id,
              },
            ];
        });
    },
    calcularIndicadores: async function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      await CertificadoService.calcularIndicadores(this.calibracaoId, this.form)
        .then((res) => {
          this.form.indicadores = res.data.faixas.map((el) => {
            el.indicador = 'MaiorDasMedias';
            el = this.buscarValorIndicador(el);
            return el;
          });
        })
        .catch(() => {})
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    colocarCertificadoAtualDeVolta: function () {
      const certificadoAtual = this.form.certificadosIds.find(
        (certificado) => certificado.value == this.form.calibracao.id
      );
      if (!certificadoAtual) {
        this.form.certificadosIds.push(new DropdownModel(this.form.calibracao));
      }
    },
  },
};
</script>
<style lang=""></style>
