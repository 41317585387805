<template>
  <div v-if="tipoCurva != 'SemCorrecao' && certificadoAtual != null">
    <template v-if="certificadoAtual.linhas.length > 0">
      <div
        v-if="tipoGraficoLocal == 'Erro'"
        ref="chartErro"
        style="width: 100%; height: 300px"
      />
      <div
        v-if="tipoGraficoLocal == 'ErroIncerteza'"
        ref="chartErroIncerteza"
        style="width: 100%; height: 300px"
      />
    </template>
  </div>
</template>

<script>
import * as echarts from 'echarts';

export default {
  props: {
    certificadoAtual: {
      type: Object,
      default: () => {
        return { linhas: [] };
      },
    },
    tipoGrafico: {
      type: String,
      default: 'Erro',
    },
    tipoCurva: {
      type: String,
      default: 'Polinomio',
    },
  },
  data: function () {
    return {
      chartErro: null,
      chartErroIncerteza: null,
      tipoGraficoLocal: null,
    };
  },
  watch: {
    certificadoAtual: {
      handler() {
        this.alterarOptions();
      },
      immediate: true
    },
    tipoGrafico: function () {
      this.alterarOptions();
    },
  },
  methods: {
    delay: function (milliseconds) {
      return new Promise((resolve) => {
        setTimeout(resolve, milliseconds);
      });
    },
    alterarOptions: async function () {
      if (this.tipoCurva == 'SemCorrecao') return;
      let dataset = this.montarDataset();
      let option = {
        dataset: dataset,
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
          },
        },
        xAxis: {
          splitLine: {
            lineStyle: {
              type: 'solid',
            },
          },
        },
        yAxis: {
          splitLine: {
            lineStyle: {
              type: 'solid',
            },
          },
        },
        series: this.montarSeries(),
      };

      this.tipoGraficoLocal = '';
      await this.delay(500);
      this.tipoGraficoLocal = this.tipoGrafico;
      await this.delay(500);

      if (this.tipoGrafico == 'ErroIncerteza') {
        this.chartErroIncerteza = echarts.init(this.$refs.chartErroIncerteza);
        this.chartErroIncerteza.setOption(option);
      } else {
        this.chartErro = echarts.init(this.$refs.chartErro);
        this.chartErro.setOption(option);
      }
    },
    retornarErrorSerie: function () {
      return {
        type: 'custom',
        name: this.$t('modulos.certificados.definir_derivas.grafico.incerteza'),
        itemStyle: {
          borderWidth: 1.5,
        },
        renderItem: function (params, api) {
          var xValue = api.value(0);
          var highPoint = api.coord([xValue, api.value(1)]);
          var lowPoint = api.coord([xValue, api.value(2)]);
          var halfWidth = 5;

          var style = api.style({
            stroke: api.visual('color'),
            fill: undefined,
          });
          return {
            type: 'group',
            children: [
              {
                type: 'line',
                transition: ['shape'],
                shape: {
                  x1: highPoint[0] - halfWidth,
                  y1: highPoint[1],
                  x2: highPoint[0] + halfWidth,
                  y2: highPoint[1],
                },
                style: style,
              },
              {
                type: 'line',
                transition: ['shape'],
                shape: {
                  x1: highPoint[0],
                  y1: highPoint[1],
                  x2: lowPoint[0],
                  y2: lowPoint[1],
                },
                style: style,
              },
              {
                type: 'line',
                transition: ['shape'],
                shape: {
                  x1: lowPoint[0] - halfWidth,
                  y1: lowPoint[1],
                  x2: lowPoint[0] + halfWidth,
                  y2: lowPoint[1],
                },
                style: style,
              },
            ],
          };
        },
        data: this.retornarPontosIncerteza(),
        z: 100,
      };
    },
    montarDataset: function () {
      let dataset = [];

      dataset.push(this.retornarPontosDistinct('erro'));

      let tendencia = this.retornarPontosDistinct('erroCorrigido');
      if (this.tipoCurva == 'Interpolacao') tendencia.source = [];
      dataset.push(tendencia);

      return dataset;
    },
    montarSeries: function () {
      let series = [
        {
          name: this.$t('modulos.certificados.definir_derivas.grafico.erro'),
          type: this.tipoCurva == 'Interpolacao' ? 'line' : 'scatter',
          datasetIndex: 0,
        },
      ];

      if (this.tipoGrafico == 'ErroIncerteza')
        series.push(this.retornarErrorSerie());

      let linhaTendencia = {
        name: this.$t('modulos.certificados.definir_derivas.grafico.tendencia'),
        type: 'line',
        smooth: true,
        datasetIndex: 1,
        symbolSize: 1,
        symbol: 'circle',
      };
      if (this.tipoCurva != 'Polinomio') linhaTendencia.smooth = false;

      series.push(linhaTendencia);

      return series;
    },
    retornarPontosDistinct: function (elemento = 'ponto') {
      let data = [];

      if (this.certificadoAtual)
        data = this.certificadoAtual.linhas
          .map((el) => [el.ponto, el[elemento]])
          .filter((value, index, array) => {
            return (
              array.indexOf(
                array.find((el) => el[0] == value[0] && el[1] == value[1])
              ) === index
            );
          });
      return { source: data };
    },

    retornarPontosIncerteza: function () {
      let data = [];

      if (this.certificadoAtual)
        data = this.certificadoAtual.linhas
          .map((el) => [
            el.ponto,
            el.erro + Math.abs(el.incerteza),
            el.erro - Math.abs(el.incerteza),
          ])
          .filter((value, index, array) => {
            return (
              array.indexOf(
                array.find(
                  (el) =>
                    el[0] == value[0] && el[1] == value[1] && el[2] == value[2]
                )
              ) === index
            );
          });
      return data;
    },
  },
};
</script>
