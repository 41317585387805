<template>
  <v-simple-table
    dense
    class="tabela-certificado"
  >
    <thead>
      <tr>
        <th
          scope="col"
          class="header-titulo"
          colspan="3"
        >
          <span class="titulo">
            {{ titulo }}
          </span>
        </th>
      </tr>

      <tr>
        <th
          scope="col"
          class="header-colunas"
        >
          {{ $t('modulos.certificados.definir_derivas.tabela_certificado.erro') }}
        </th>
        <th
          scope="col"
          class="header-colunas"
        >
          {{ $t('modulos.certificados.definir_derivas.tabela_certificado.erro_corrigido') }}
        </th>
        <th
          scope="col"
          class="header-colunas"
        >
          {{ $t('modulos.certificados.definir_derivas.tabela_certificado.deriva') }}
        </th>
      </tr>
    </thead>

    <tbody>
      <tr
        v-for="(linha, index) in linhas"
        :key="index"
        :class="{'bg-primary-grey': !(index % 2 === 0)}"
      >
        <td style="width: 100px;">
          <span v-if="temCertificado">{{ linha.erro | formatarNumeroComPrecisao }}</span>
        </td>
        <td>
          <span v-if="temCertificado">{{ linha.erroCorrigido | formatarNumeroComPrecisao }}</span>
        </td>
        <td class="coluna-deriva" style="width: 150px;">
          <input-text
            :ref="`campo-${index}`"
            v-if="temCertificado"
            v-model="linha.deriva"
            type="number"
            :value="0"
            class="flex-fill input-tabela my-1"
            sem-label
            :disabled="visualizacao"
            @tab="proximoCampo(index)"
            tab-no-enter
            prevent-tab
            @focus="selecionaValorInput(`campo-${index}`)"
          />
        </td>
      </tr>
    </tbody>
  </v-simple-table>
</template>

<script>
export default {
  props: {
    titulo: {
      type: String,
      required: true
    },

    linhas: {
      type: Array,
      default: () => []
    },
    temCertificado: {
      type: Boolean,
      default: true
    },
    visualizacao : {
      type : Boolean,
      default : false
    },
  },
  methods : {
    proximoCampo(indice){
      if(!this.$refs[`campo-${indice+1}`]){
        this.$emit("proximo-certificado");
        return;
      }
      this.$refs[`campo-${indice+1}`][0].focus();
    },
    focus(){
      this.$refs['campo-0'][0].focus();
    },
    selecionaValorInput: function(refInput) {
      this.$refs[refInput][0].$refs.input.$refs.input.select()
    }
  }
}
</script>

<style lang="scss">
.tabela-certificado {
  .header-titulo {
    background-color: $color-primary-grey-300;

    .titulo {
      white-space: nowrap;
      font-size: 16px;
    }
  }

  .header-colunas {
    background-color: $color-primary-grey-200;
  }

  tr {
    height: 40px;

    &.bg-primary-grey {
      background-color: $color-primary-grey-100;
    }

    .coluna-deriva {
      min-width: 100px;
    }
  }
}
</style>
