<template>
  <v-simple-table
    dense
    class="tabela-pontos"
  >
    <thead>
      <tr class="w-100">
        <th
          scope="col"
          class="header-titulo"
        />
        <th
          scope="col"
          class="header-titulo"
        />
      </tr>
    </thead>

    <tbody>
      <tr>
        <th
          scope="col"
          class="header-colunas"
        >
          {{ $t('modulos.certificados.definir_derivas.tabela_pontos.ponto') }}
        </th>
        <th
          scope="col"
          class="header-colunas"
        >
          {{ $t('modulos.certificados.definir_derivas.tabela_pontos.nome') }}
        </th>
      </tr>

      <tr
        v-for="(ponto, index) in pontos"
        :key="index"
        :class="{'bg-primary-grey': !(index % 2 === 0)}"
      >
        <td>
          <span>{{ ponto.valor | formatarNumeroComPrecisao }}</span>
        </td>
        <td>
          <span style="text-overflow: clip; text-wrap: nowrap;">{{ ponto.nome }}</span>
        </td>
      </tr>
    </tbody>
  </v-simple-table>
</template>

<script>
export default {
  props: {
    pontos: {
      type: Array,
      default: () => []
    },
  },
}
</script>

<style lang="scss">
.tabela-pontos {
  .header-titulo {
    background-color: $color-primary-grey-300;

    .titulo {
      white-space: nowrap;
    }
  }

  .header-colunas {
    background-color: $color-primary-grey-200;
  }

  tr {
    height: 40px;

    &.bg-primary-grey {
      background-color: $color-primary-grey-100;
    }
  }
}
</style>



