<template>
  <div>
    {{ filtroGlobal.instrumentoFaixaSelecionadoId }}
    <v-form
      ref="form"
      class="row"
    >
      <input-select
        v-model="filtroGlobal.grafico"
        class="col"
        :label="$t('modulos.certificados.definir_derivas.filtro.grafico')"
        :options="opcoes.grafico"
      />

      <input-select
        :key="keySelect"
        v-model="filtroGlobal.instrumentoFaixaSelecionadoId"
        class="col-4"
        :label="$t('modulos.certificados.definir_derivas.filtro.faixa_instrumento')"
        :options="faixasInstrumento"
      />

      <input-select
        v-model="filtro.curvaCorrecao"
        class="col"
        :label="$t('modulos.certificados.definir_derivas.filtro.curva_correcao')"
        :options="opcoesCurvaCorrecao"
        obrigatorio
        :disabled="visualizacao"
      />

      <input-text
        ref="inputGrauPolinomio"
        v-model="filtro.grauPolinomio"
        class="col"
        type="number"
        :label="$t('modulos.certificados.definir_derivas.filtro.grau_polinomio')"
        :placeholder="$t('modulos.certificados.definir_derivas.filtro.grau_polinomio')"
        :disabled="filtro.curvaCorrecao !== 'Polinomio' || visualizacao"
        :obrigatorio="filtro.curvaCorrecao === 'Polinomio'"
        :regras-personalizadas="regrasGrauPolinomio"
        :numero-minimo="filtro.curvaCorrecao === 'Polinomio'? 1 : null"
        :numero-maximo="filtro.curvaCorrecao === 'Polinomio' ? maximoGrauPolinomio : null"
        @focus="selecionaValorInput('inputGrauPolinomio')"
      />
        
      <input-text
        ref="inputPercentualLeituras"
        v-model="filtro.percentual"
        class="col"
        type="number"
        :label="$t('modulos.certificados.definir_derivas.filtro.percentual_leituras')"
        :placeholder="$t('modulos.certificados.definir_derivas.filtro.percentual_leituras')"
        :disabled="filtro.curvaCorrecao !== 'Percentual' || visualizacao"
        :numero-minimo="filtro.curvaCorrecao === 'Percentual' ? -100 : null"
        :numero-maximo="filtro.curvaCorrecao === 'Percentual' ? 100 : null"
        :obrigatorio="filtro.curvaCorrecao === 'Percentual'"
        @focus="selecionaValorInput('inputPercentualLeituras')"
      />
    </v-form>
  </div>
</template>
<script>
import { EnumeradorService } from '@common/services';
import { DropdownModel } from '@common/models/geral/DropdownModel'
export default {
  props : {
    filtro : {
      type: Object,
      required : true
    },
    instrumento : {
      type: Object,
      required : true
    },
    calibracao : {
      type: Object,
      required : true
    },
    filtroGlobal : {
      type: Object,
      required : true
    },
    pontos : {
      type: Array,
      default : () => []
    },
    visualizacao : {
      type : Boolean,
      default : false
    },
    keySelect: {
      type: Number,
      default: 1,
    },
  },
  data : function(){
    return {
      opcoes: {
        curvaCorrecao: [],
        grafico: [
          {
            value: 'Erro',
            text: this.$t(
              'modulos.certificados.definir_derivas.opcoes.graficos.erro'
            ),
          },
          {
            value: 'ErroIncerteza',
            text: this.$t(
              'modulos.certificados.definir_derivas.opcoes.graficos.erro_incerteza'
            ),
          },
        ],
      },
    }
  },
  computed : {
    maximoGrauPolinomio() {
      this.$refs.form?.resetValidation();
      if (this.pontos.length == 0) return 0;
      if (this.pontos.length > 6) return 6;
      return this.pontos.length - 1;
    },
    faixasInstrumento : function(){
      return new DropdownModel(this.calibracao.faixas, 'descricao');
    },
    opcoesCurvaCorrecao : function(){
      if(!this.filtroGlobal.instrumentoFaixaSelecionadoId) return [];

      if(this.calibracao.faixas.find(c => c.id == this.filtroGlobal.instrumentoFaixaSelecionadoId).pontos.length == 1){
        return this.opcoes.curvaCorrecao.filter(el => el.value != 'Polinomio');
      }

      return this.opcoes.curvaCorrecao;
    },
    regrasGrauPolinomio : function(){
      let max = this.maximoGrauPolinomio;
      let ctx = this;
      if(this.filtro.curvaCorrecao !== 'Polinomio') return [];
      return [
        function(v){
          return v > 0 && v <= max || ctx.$t('geral.regras.derivas.grau_polinomio', {min : 0, max : max});
        }
      ]
    }
  },
  mounted() {
    this.buscarCurvaCorrecaoEnum();
    this.definirValorCurvaCorrecaoInicial();
  },
  methods  :{
    buscarCurvaCorrecaoEnum: function () {
      this.$store.dispatch('Layout/iniciarCarregamento');

      EnumeradorService.buscar('EnumCurvaCorrecao').then((res) => {
        this.opcoes.curvaCorrecao = res;
      }).finally(() => {
        this.$store.dispatch('Layout/terminarCarregamento');
      });
    },
    selecionaValorInput: function(refInput) {
      this.$refs[refInput].$refs.input.$refs.input.select()
    },
    definirValorCurvaCorrecaoInicial: function() {
      if (this.filtroGlobal.instrumentoFaixaSelecionadoId) {
        const faixaSelecionada = this.calibracao.faixas.find(c => c.id == this.filtroGlobal.instrumentoFaixaSelecionadoId);
        if (faixaSelecionada.pontos.length == 1) {
          this.filtro.curvaCorrecao = 'Interpolacao';
        }
      }
    }
  }
}
</script>