<template>
  <div>
    <!-- <v-alert v-if="!podeSalvar" class="mt-2" type="warning" dense>
      {{ $t('modulos.certificados.definir_derivas.alerta_conformidades') }}
    </v-alert> -->
    <v-form>
      <div
        v-for="conformidade in opcoes.conformidades"
        :key="conformidade.id"
        class="d-flex align-center"
      >
        <span>{{ conformidade.nome }}</span>
        <v-divider class="mx-4" />
        <input-select
          v-model="buscarValor(conformidade.id).conforme"
          :disabled="visualizacao"
          :options="opcoes.conformes"
          :placeholder="
            $t(
              'modulos.certificados.definir_derivas.modal_conformidades.selecione'
            )
          "
          class="w-25"
        />
      </div>

      <v-divider
        v-if="!visualizacao"
        class="my-2"
      />

      <div
        v-if="!visualizacao"
        class="ma-0 d-flex justify-end"
      >
        <botao-padrao
          class="mr-2"
          outlined
          color="secondary"
          @click="cancelar"
        >
          <v-icon>$mdiCancel</v-icon>
          {{ $t('geral.botoes.cancelar') }}
        </botao-padrao>

        <botao-padrao
          :disabled="!podeSalvar"
          :tooltip="tooltip"
          @click="salvar"
        >
          {{ $t('geral.botoes.salvar') }}
        </botao-padrao>
      </div>
    </v-form>
  </div>
</template>
<script>
import { EnumeradorService, CertificadoService } from '@common/services';
export default {
  props: {
    calibracaoId: {
      type: String,
      required: true,
    },
    form: {
      type: Array,
      default: () => [],
    },
    visualizacao: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      opcoes: {
        conformidades: [],
        conformes: [],
      },
    };
  },
  computed: {
    podeSalvar: function () {
      return this.form.every(
        (el) => !!el.conforme && el.conforme != 'NaoConforme'
      );
    },
    tooltip: function () {
      if (this.podeSalvar) return '';
      return this.$t(
        'modulos.certificados.definir_derivas.alerta_conformidades'
      );
    },
  },
  mounted() {
    this.buscarConformidadeItems();
    this.buscarConformesEnum();
  },
  methods: {
    buscarValor: function (id) {
      let obj = this.form.find((el) => el.conformidadeItemId == id);
      if (!obj) {
        this.form.push({ conformidadeItemId: id, conforme: null });
        return this.buscarValor(id);
      }
      return obj;
    },
    buscarConformesEnum: function () {
      this.$store.dispatch('Layout/iniciarCarregamento');

      EnumeradorService.buscar('EnumConformidadeItemConforme')
        .then((response) => {
          this.opcoes.conformes = response;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    buscarConformidadeItems: function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      CertificadoService.buscarConformidadeItems()
        .then((response) => {
          this.opcoes.conformidades = response.data.map((conformidade) => ({
            ...conformidade,
            conforme: null,
          }));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    cancelar: function () {
      this.$emit('cancelar');
    },
    salvar: function () {
      this.$store.dispatch('Layout/iniciarCarregamento');

      CertificadoService.salvarConformidades(this.calibracaoId, {
        itens: this.form,
      })
        .then(() => {
          this.toastSucesso(
            this.$t(
              'modulos.certificados.definir_derivas.conformidades_sucesso'
            )
          );
          this.$emit('buscar-conformidade');
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
  },
};
</script>
