<template>
  <div>
    <!-- <v-alert
      v-if="!faixasPreenchidas"
      class="mt-2"
      type="warning"
      dense
    >
      {{ $t('modulos.certificados.definir_derivas.alerta_faixas') }}
    </v-alert> -->
    <formulario-filtro
      v-if="!!dadosSelecionado"
      ref="formulario-filtro"
      :instrumento="instrumento"
      :calibracao="calibracao"
      :filtro="dadosSelecionado.filtro"
      :filtro-global="filtroGlobal"
      :pontos="pontos"
      :visualizacao="visualizacao"
      :key-select="keySelect"
    />
    <grafico-deriva
      v-if="!!dadosSelecionado"
      :key="keyGrafico"
      :certificado-atual="dadosSelecionado.certificadoAtual"
      :tipo-grafico="filtroGlobal.grafico"
      :tipo-curva="dadosSelecionado.filtro.curvaCorrecao"
    />
    <input-text
      v-if="!!dadosSelecionado"
      :value="dadosSelecionado.formulaCurva"
      :label="$t('modulos.certificados.definir_derivas.formula_curva')"
      :placeholder="$t('modulos.certificados.definir_derivas.formula_curva')"
      disabled
    />

    <div
      v-if="
        !!dadosSelecionado &&
          (dadosSelecionado.ultimoCertificado ||
            dadosSelecionado.certificadoAtual)
      "
      class="row pa-4 mt-2"
    >
      <tabela-pontos
        class="col-2 pa-0 tabela-pontos-border"
        :pontos="pontos"
      />
      <tabela-certificado
        class="col-5 pa-0 tabela-ultimo-certificado-border"
        :titulo="tituloTabelaUltimoCertificado"
        :linhas="linhasUltimoCertificado"
        :tem-certificado="!!dadosSelecionado.ultimoCertificado"
        :visualizacao="visualizacao"
        @proximo-certificado="focarCertificadoAtual"
      />
      <tabela-certificado
        ref="certificadoAtual"
        class="col-5 pa-0 tabela-certificado-atual-border"
        :titulo="tituloTabelaCertificadoAtual"
        :linhas="linhasCertificadoAtual"
        :visualizacao="visualizacao"
        @proximo-certificado="confirmarSalvarFaixa"
      />
    </div>
    <v-divider
      v-if="!visualizacao"
      class="my-2"
    />
    <div
      v-if="!visualizacao"
      class="ma-0 d-flex align-center justify-end"
    >
      <botao-padrao
        class="mr-2"
        outlined
        color="secondary"
        @click="cancelar"
      >
        <v-icon>$mdiCancel</v-icon>
        {{ $t('geral.botoes.cancelar') }}
      </botao-padrao>

      <botao-padrao
        v-if="!!dadosSelecionado"
        :disabled="
          !dadosSelecionado.ultimoCertificado &&
            !dadosSelecionado.certificadoAtual
        "
        @click="confirmarSalvarFaixa"
      >
        {{ $t('geral.botoes.salvar') }}
      </botao-padrao>
    </div>
  </div>
</template>
<script>
import FormularioFiltro from './components/FormularioFiltro';
import GraficoDeriva from './components/GraficoDeriva';
import TabelaPontos from './components/tabelaPontos';
import TabelaCertificado from './components/tabelaCertificado';
import { CertificadoService } from '@common/services';
export default {
  components: {
    FormularioFiltro,
    GraficoDeriva,
    TabelaCertificado,
    TabelaPontos,
  },
  props: {
    calibracaoId: {
      type: String,
      required: true,
    },
    instrumento: {
      type: Object,
      required: true,
    },
    calibracao: {
      type: Object,
      required: true,
    },
    visualizacao: {
      type: Boolean,
      default: false,
    },
    faixasPreenchidas: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      keyGrafico: 1,
      keySelect: 1,
      watchers: {
        curvaCorrecao: null,
        grauPolinomio: null,
        percentual: null,
        faixa: null,
      },
      debounce: null,
      debounceFaixa: null,
      watchHabilitado: false,
      watchHabilitadoFaixa: true,
      
      dadosFaixas: [],
      filtroGlobal: {
        grafico: 'ErroIncerteza',
        instrumentoFaixaSelecionadoId: null,
      },
    };
  },
  computed: {
    dadosSelecionado: function () {
      return this.dadosFaixas.find(
        (el) => el.faixaId == this.filtroGlobal.instrumentoFaixaSelecionadoId
      )?.dados;
    },
    pontos() {
      let todosPontos = [];

      if (this.dadosSelecionado.certificadoAtual) {
        todosPontos = [...this.dadosSelecionado.certificadoAtual.linhas];
      }

      if (this.dadosSelecionado.ultimoCertificado) {
        todosPontos = [
          ...todosPontos,
          ...this.dadosSelecionado.ultimoCertificado.linhas,
        ];
      }

      todosPontos = todosPontos.sort((a, b) => a.ponto - b.ponto);

      const retorno = [];

      for (const ponto of todosPontos) {
        const pesquisa = retorno.find(
          (e) => e.ponto === ponto.ponto && e.nome === ponto.nome
        );

        if (!pesquisa) {
          retorno.push(ponto);
        }
      }
      return retorno.map(({ ponto: valor, nome }) => ({ nome, valor }));
    },
    tituloTabelaCertificadoAtual() {
      return this.$t(
        'modulos.certificados.definir_derivas.tabela_certificado.certificado_atual',
        {
          nome: `${this.dadosSelecionado.certificadoAtual ? ' - ' : ''}${
            this.dadosSelecionado.certificadoAtual?.certificado.nome
          }`,
        }
      );
    },
    tituloTabelaUltimoCertificado() {
      return this.$t(
        'modulos.certificados.definir_derivas.tabela_certificado.ultimo_certificado',
        {
          nome: `${this.dadosSelecionado.ultimoCertificado ? ' - ' : ''}${
            this.dadosSelecionado.ultimoCertificado
              ? this.dadosSelecionado.ultimoCertificado?.certificado?.nome
              : ''
          }`,
        }
      );
    },
    linhasCertificadoAtual() {
      if (this.dadosSelecionado.certificadoAtual)
        return this.dadosSelecionado.certificadoAtual.linhas;

      return new Array(this.pontos.length).fill({
        erro: '',
        erroCorrigido: '',
        deriva: ' ',
      });
    },
    linhasUltimoCertificado() {
      if (this.dadosSelecionado.ultimoCertificado)
        return this.dadosSelecionado.ultimoCertificado.linhas;

      return new Array(this.pontos.length).fill({
        erro: '',
        erroCorrigido: '',
        deriva: ' ',
      });
    },
  },
  watch: {
    "filtroGlobal.instrumentoFaixaSelecionadoId": function (novo, antigo) {
      if(!this.watchHabilitadoFaixa) return this.watchHabilitadoFaixa = true;
      if(novo === antigo || !antigo) return;
      if(novo !== antigo) this.salvarAoTrocarFaixa(novo, antigo);
    },
  },
  mounted() {
    let ctx = this;
    const func = function () {
      return function () {
        if (!this.watchHabilitado) return;
        ctx.removerWatchers();
        ctx.calcularDerivas(ctx);
      };
    };

    this.watchers.faixa = this.$watch(
      'filtroGlobal.instrumentoFaixaSelecionadoId',
      function () {
        clearTimeout(this.debounceFaixa);
        this.watchHabilitado = false;
        this.debounceFaixa = setTimeout(() => {
          this.watchHabilitado = true;
        }, 800);
      }
    );

    this.watchers.curvaCorrecao = this.$watch(
      'dadosSelecionado.filtro.curvaCorrecao',
      func('curvaCorrecao')
    );
    this.watchers.grauPolinomio = this.$watch(
      'dadosSelecionado.filtro.grauPolinomio',
      func('grauPolinomio')
    );
    this.watchers.percentual = this.$watch(
      'dadosSelecionado.filtro.percentual',
      func('percentual')
    );
  },
  methods: {
    dadosSelecionadoCustom: function (faixaId) {
      return this.dadosFaixas.find(
        (el) => el.faixaId == faixaId
      )?.dados;
    },
    salvarAoTrocarFaixa: function (novo, antigo) {
      if (!this.validarFormulario()) {
        this.watchHabilitadoFaixa = false;
        ++this.keySelect;
        return this.filtroGlobal.instrumentoFaixaSelecionadoId = antigo;
      }
      this.salvar(false, antigo);
    },
    validarFormulario: function () {
      return this.$refs['formulario-filtro']?.$refs.form.validate();
    },
    focarCertificadoAtual: function () {
      this.$refs.certificadoAtual.focus();
    },
    remontarGrafico: function () {
      this.keyGrafico++;
    },
    inicializar: function () {
      if (!this.calibracao.faixas.length) return;
      this.filtroGlobal.instrumentoFaixaSelecionadoId =
        this.calibracao.faixas[0].id;
      this.calibracao.faixas.forEach((faixa) => {
        this.buscarConformidade(faixa.id, faixa.pontos.length);
      });
    },
    definirDadosFaixas: function (
      faixaId,
      {
        curvaCorrecao,
        grauPolinomio,
        percentual,
        formulaCurva,
        ultimoCertificado,
        certificadoAtual,
      }
    ) {
      this.validarFormulario();
      let faixa = this.dadosFaixas.find((el) => el.faixaId == faixaId);
      const dados = {
        filtro: {
          curvaCorrecao: curvaCorrecao,
          grauPolinomio: grauPolinomio,
          percentual: percentual,
        },
        formulaCurva: formulaCurva,
        ultimoCertificado: ultimoCertificado,
        certificadoAtual: certificadoAtual,
      };
      if (!faixa) {
        this.dadosFaixas.push({
          faixaId: faixaId,
          dados: dados,
        });
      } else {
        faixa.dados = {
          filtro: {
            curvaCorrecao: curvaCorrecao,
            grauPolinomio: grauPolinomio,
            percentual: percentual,
          },
          formulaCurva: formulaCurva,
          ultimoCertificado: ultimoCertificado,
          certificadoAtual: certificadoAtual,
        };
      }
    },
    buscarConformidade: async function (faixaId, pontos = null) {
      this.removerWatchers();

      this.definirDadosFaixas(faixaId, {
        curvaCorrecao:
          pontos === null
            ? 'Polinomio'
            : pontos > 0
            ? 'Polinomio'
            : 'Percentual',
        grauPolinomio:
          pontos === null
            ? '1'
            : pontos > 0
            ? pontos > 6
              ? 6
              : pontos - 1
            : '',
        percentual: '',
        formulaCurva: '',
        ultimoCertificado: null,
        certificadoAtual: null,
      });

      CertificadoService.buscarConformidade(this.calibracaoId, faixaId)
        .then((res) => {
          this.definirDadosFaixas(faixaId, {
            ...res.data,
            certificadoAtual: res.data.certificado,
          });

          this.$emit('mudar-certificado-atual', this.certificadoAtual);
        })
        .catch(() => {
          this.calcularConformidade(faixaId);
        })
        .finally(() => {
          this.definirWatchers();
        });
    },
    definirWatchers: function () {
      this.watchHabilitado = true;
    },
    removerWatchers: function () {
      if (this.watchers.curvaCorrecao == null) return;
      this.watchHabilitado = false;
    },
    calcularDerivas: function (ctx) {
      if (!ctx.filtroGlobal.instrumentoFaixaSelecionadoId) return;

      if (
        ctx.calibracao.faixas.find(
          (c) => c.id == ctx.filtroGlobal.instrumentoFaixaSelecionadoId
        ).pontos.length == 1 &&
        ctx.dadosSelecionado.filtro.curvaCorrecao == 'Polinomio'
      ) {
        ctx.dadosSelecionado.filtro.curvaCorrecao = 'Percentual';
      }

      if (
        ctx.dadosSelecionado.filtro.curvaCorrecao == 'Polinomio' &&
        (ctx.dadosSelecionado.filtro.grauPolinomio === '' ||
          ctx.dadosSelecionado.filtro.grauPolinomio === null)
      ) {
        this.definirWatchers();
        return;
      }
      if (
        ctx.dadosSelecionado.filtro.curvaCorrecao == 'Percentual' &&
        (ctx.dadosSelecionado.filtro.percentual === '' ||
          ctx.dadosSelecionado.filtro.percentual === null)
      ) {
        this.definirWatchers();
        return;
      }

      ctx.calcularConformidade(this.filtroGlobal.instrumentoFaixaSelecionadoId);
    },
    calcularConformidade: function (faixaId) {
      const dadosFaixa = this.dadosFaixas.find(
        (el) => el.faixaId == faixaId
      ).dados;

      let payload = {
        curvaCorrecao: dadosFaixa.filtro.curvaCorrecao,
      };

      if (dadosFaixa.filtro.curvaCorrecao == 'Polinomio')
        payload.grauPolinomio = dadosFaixa.filtro.grauPolinomio;
      if (dadosFaixa.filtro.curvaCorrecao == 'Percentual')
        payload.percentual = dadosFaixa.filtro.percentual;

      this.$store.dispatch('Layout/iniciarCarregamento');

      CertificadoService.calcularConformidade(
        this.calibracaoId,
        faixaId,
        payload
      )
        .then((response) => {
          const { data } = response;

          this.definirDadosFaixas(faixaId, {
            curvaCorrecao: dadosFaixa.filtro.curvaCorrecao,
            grauPolinomio: dadosFaixa.filtro.grauPolinomio,
            percentual: dadosFaixa.filtro.percentual,
            formulaCurva: data.formulaCurva,
            ultimoCertificado: data.ultimoCertificado,
            certificadoAtual: data.certificado,
          });

          this.$emit(
            'mudar-certificado-atual',
            this.dadosSelecionado.certificadoAtual
          );
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        })
        .finally(() => {
          this.definirWatchers();
        });
    },
    cancelar: function () {
      this.$emit('cancelar');
    },
    confirmarSalvarFaixa: function () {
      if (!this.$refs['formulario-filtro'].$refs.form.validate()) return;
      this.salvar(true);
    },
    salvar: function (alertarUsuario = false, faixaId = null) {

      const dadosSelecionado = this.dadosSelecionadoCustom(
        faixaId ?? this.filtroGlobal.instrumentoFaixaSelecionadoId
      );
      const certificado = dadosSelecionado.certificadoAtual.linhas.map(
        (linha) => ({
          calibracaoLinhaId: linha.linhaId,
          deriva: parseFloat(linha.deriva),
        })
      );

      let ultimoCertificado = [];

      if (this.ultimoCertificado) {
        ultimoCertificado = dadosSelecionado.ultimoCertificado.linhas.map(
          (linha) => ({
            calibracaoLinhaId: linha.linhaId,
            deriva: parseFloat(linha.deriva),
          })
        );
      }

      const payload = {
        curvaCorrecao: dadosSelecionado.filtro.curvaCorrecao,
        grauPolinomio: dadosSelecionado.filtro.grauPolinomio,
        percentual: dadosSelecionado.filtro.percentual,
        certificado,
        ultimoCertificado,
      };

      this.$store.dispatch('Layout/iniciarCarregamento');

      CertificadoService.salvarConformidade(
        this.calibracaoId,
        faixaId ?? this.filtroGlobal.instrumentoFaixaSelecionadoId,
        payload
      )
        .then(() => {
          this.toastSucesso(
            this.$t('modulos.certificados.definir_derivas.salvar_sucesso')
          );
          this.$emit('validar-faixas', alertarUsuario);
          setTimeout(() => {
            alertarUsuario && this.alertaUsuarioSeTemDerivaSemSalvar()
          }, 1000);
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    alertaUsuarioSeTemDerivaSemSalvar: function () {

      if (!this.faixasPreenchidas)
        return this.toastAlerta(
          this.$t(
            `modulos.certificados.definir_derivas.alerta_faixas`
          ),{position: 'botton'}
        );
    },
  },
};
</script>
